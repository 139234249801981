<template>
  <div class="first-header">
    <div @click="toPagePlace('home')" class="header-logo">
      <img class="logo-img" src="../../assets/images/header-logo.png" alt="" />
    </div>
    <div class="header-right">
      <span
        :class="[nowCheckedValue == 'home' ? 'right-item-active' : '']"
        @click="toPagePlace('home')"
        class="right-item right-item1"
        >首页</span
      >
      <span
        :class="[nowCheckedValue == 'download' ? 'right-item-active' : '']"
        @click="toPagePlace('download')"
        class="right-item right-item1"
        >下载</span
      >
      <span
        :class="[nowCheckedValue == 'price' ? 'right-item-active' : '']"
        @click="toPagePlace('price')"
        class="right-item right-item1"
        >定价</span
      >
      <span
        :class="[
          nowCheckedValue == 'businessCooperation' ? 'right-item-active' : '',
        ]"
        @click="toOtherPage('/businessCooperation')"
        class="right-item"
        >商务合作</span
      >
      <span
        :class="[nowCheckedValue == 'business' ? 'right-item-active' : '']"
        @click="toOtherPage('/business')"
        class="right-item"
        >集团版</span
      >
      <a
        href="https://web2.homepms.com"
        target="_blank"
        class="register-btn d-flex-center"
        >注册</a
      >
      <a
        href="https://web2.homepms.com"
        target="_blank"
        class="login-btn d-flex-center"
        >登录</a
      >
    </div>
    <img
      @click.stop="menuListShow = true"
      v-if="!menuListShow"
      class="menu-img"
      src="../../assets/images/menu.png"
      alt=""
    />
    <img
      @click="menuListShow = false"
      v-else
      class="menu-img"
      src="../../assets/images/menu-close.png"
      alt=""
    />
    <MenuDownList
      v-model="menuListShow"
      @toPagePlace="toPagePlace"
      :nowCheckedValue="nowCheckedValue"
    />
  </div>
</template>

<script>
import MenuDownList from "@/components/MenuDownList";
export default {
  components: {
    MenuDownList,
  },
  data() {
    return {
      nowCheckedValue: "home",
      menuListShow: false,
    };
  },
  created() {
    if (this.$route.path === "/businessCooperation") {
      this.nowCheckedValue = "businessCooperation";
    }
    window.addEventListener("scroll", this.appScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.appScroll);
  },
  methods: {
    closeMenuList() {
      this.menuListShow = false;
    },
    toOtherPage(url) {
      this.$router.push(url);
    },
    toPagePlace(url) {
      this.$router.push("/");
      let timer = setTimeout(() => {
        clearTimeout(timer);
        let topHeight = 0;
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        if (url === "download") {
          topHeight =
            document.getElementById("download").getBoundingClientRect().top +
            scrollTop;
        } else if (url == "price") {
          topHeight =
            document.getElementById("price").getBoundingClientRect().top +
            scrollTop;
        }
        this.fnc_scrollto(topHeight);
      }, 50);
    },
    fnc_scrollto(to, id) {
      var smoothScrollFeature =
        "scrollBehavior" in document.documentElement.style;
      var articles = document.querySelectorAll("ul#content > li");
      if (to == "elem") to = articles[id].offsetTop;
      var i = parseInt(window.pageYOffset);
      if (i != to) {
        if (!smoothScrollFeature) {
          to = parseInt(to);
          if (i < to) {
            let int = setInterval(function () {
              if (i > to - 20) i += 1;
              else if (i > to - 40) i += 3;
              else if (i > to - 80) i += 8;
              else if (i > to - 160) i += 18;
              else if (i > to - 200) i += 24;
              else if (i > to - 300) i += 40;
              else i += 60;
              window.scroll(0, i);
              if (i >= to) clearInterval(int);
            }, 15);
          } else {
            let int = setInterval(function () {
              if (i < to + 20) i -= 1;
              else if (i < to + 40) i -= 3;
              else if (i < to + 80) i -= 8;
              else if (i < to + 160) i -= 18;
              else if (i < to + 200) i -= 24;
              else if (i < to + 300) i -= 40;
              else i -= 60;
              window.scroll(0, i);
              if (i <= to) clearInterval(int);
            }, 15);
          }
        } else {
          window.scroll({
            top: to,
            left: 0,
            behavior: "smooth",
          });
        }
      }
    },
    appScroll() {
      if (this.$route.path !== "/") return;
      let topHeight1 =
        document.getElementById("download").getBoundingClientRect().top +
        document.documentElement.scrollTop -
        70;
      let topHeight2 =
        document.getElementById("price").getBoundingClientRect().top +
        document.documentElement.scrollTop -
        70;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop < topHeight1) {
        if (this.$route.path === "/businessCooperation") {
          this.nowCheckedValue = "businessCooperation";
        } else {
          this.nowCheckedValue = "home";
        }
      } else if (scrollTop >= topHeight1 && scrollTop < topHeight2) {
        this.nowCheckedValue = "download";
      } else {
        this.nowCheckedValue = "price";
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.first-header {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100vw;
  height: 70px;
  padding: 0 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(252, 252, 252, 0.3);
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  @media screen and (max-width: 749px) {
    padding-left: 20px;
    padding-right: 15px;
  }
  .header-logo {
    &:hover {
      cursor: pointer;
    }
    .logo-img {
      width: 144px;
      height: 52px;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    @media screen and (max-width: 749px) {
      display: none;
    }
    .right-item {
      margin-left: 45px;
      color: #333;
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        cursor: pointer;
        color: #268ff8;
      }
    }
    .right-item1 {
      display: block;
      @media screen and (max-width: 1000px) {
        display: none;
      }
    }

    .right-item-active {
      color: #268ff8;
    }
    .register-btn {
      width: 100px;
      height: 40px;
      border-radius: 30px;
      background: rgba(38, 143, 248, 1);
      color: #fff;
      margin-left: 70px;
      user-select: none;
      &:hover {
        cursor: pointer;
        background: #3c9af9;
      }
    }
    .login-btn {
      width: 100px;
      height: 40px;
      border-radius: 30px;
      border: 0.5px solid rgba(38, 143, 248, 0.5);
      background: rgba(38, 143, 248, 0.05);
      color: #268ff8;
      margin-left: 20px;
      user-select: none;
      &:hover {
        cursor: pointer;
        background: rgba(38, 143, 248, 0.1);
      }
    }
  }
  .menu-img {
    width: 44px;
    height: 44px;
    display: none;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 749px) {
      display: block;
    }
  }
}
</style>