// 对axios进行二次封装
import axios from "axios";
// 1.利用axios对象的方法creta  去创建一个axios实例
// request其实就是axios,只不过是稍微配置下
const request = axios.create({
  // 配置对象
  // 基础路径。发请求的时候，路径当中会出现api
  //baseURL: 'https://api2.withlight.cloud',
    baseURL: 'https://api2.homepluscloud.com',
  // 代表请求超时的时间5s
  timeout: 5000,
});

request.interceptors.request.use((config) => {
  return config;
});

// 响应拦截器
request.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    console.log(err);
  }
);
export default request;
