<template>
  <div class="appointment-demo">
    <div class="demo-header">预约演示Demo</div>
    <div class="demo-tip">
      <span
        >请输入以下信息，24小时内会有专人与您联络。如有紧急需求，请联络林经理：</span
      >
      <span style="font-weight: 500">18122395056</span>
    </div>
    <div class="demo-post">
      <div class="demo-item">
        <div class="item-left">
          <span class="dot">*</span>
          <span class="label-text">企业名称</span>
        </div>
        <input
          v-model.trim="postObj.enterpriseName"
          placeholder="请输入您的企业名称"
          class="right-input"
          type="text"
        />
      </div>
      <div class="demo-item">
        <div class="item-left">
          <span class="dot">*</span>
          <span class="label-text">您的姓名</span>
        </div>
        <input
          v-model.trim="postObj.realName"
          placeholder="请输入您的姓名"
          class="right-input"
          type="text"
        />
      </div>
      <div class="demo-item">
        <div class="item-left">
          <span class="dot">*</span>
          <span class="label-text">手机号码</span>
        </div>
        <input
          v-model.trim="postObj.tel"
          placeholder="请输入您的手机号码"
          class="right-input"
          type="text"
        />
      </div>
      <div class="demo-item">
        <div class="item-left">
          <span class="dot">*</span>
          <span class="label-text">邮箱</span>
        </div>
        <input
          v-model.trim="postObj.email"
          placeholder="请输入您的邮箱"
          class="right-input"
          type="text"
        />
      </div>
      <div class="textarea-bottom">
        <div class="top">
          <span class="dot">*</span>
          <span class="label-text">描述需求</span>
        </div>
        <textarea
          v-model.trim="postObj.demand"
          class="bottom-textarea"
          placeholder="请描述您的需求"
        ></textarea>
        <span class="text-num">{{ textNum }}</span>
      </div>
    </div>
    <div @click="postAppointment" class="post-btn d-flex-center">提交</div>
  </div>
</template>

<script>
import { reqPostAppointmentDemo } from "@/api";
const checkPhoneReg = /^1[3456789]\d{9}$/;
const checkEmailReg =
  /[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
export default {
  data() {
    return {
      textNum: 0,
      postObj: {
        enterpriseName: "",
        realName: "",
        tel: "",
        email: "",
        demand: "",
      },
    };
  },
  components: {},
  watch: {
    "postObj.demand"(n) {
      if (n) {
        this.textNum = n.length;
        if (this.textNum > 240) {
          this.postObj.demand = this.postObj.demand.slice(0, 240);
        }
      } else {
        this.textNum = 0;
      }
    },
  },
  methods: {
    async postAppointment() {
      try {
        if (!Object.values(this.postObj).every((item) => item !== "")) {
          this.$message.error("请将预约信息填完整!");
          return;
        }
        if (!checkPhoneReg.test(this.postObj.tel)) {
          this.$message.error("手机号码格式错误！");
          return;
        }
        if (!checkEmailReg.test(this.postObj.email)) {
          this.$message.error("邮箱格式错误!");
          return;
        }
        const res = await reqPostAppointmentDemo(this.postObj);
        if (res.success) {
          this.$message.success("预约成功");
        } else {
          this.$message.error(res.message);
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.appointment-demo {
  width: 100%;
  min-height: calc(100vh - 330px);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1299px) {
    min-height: calc(100vh - 350px);
  }
  @media screen and (max-width: 1000px) {
    min-height: calc(100vh - 390px);
  }
  @media screen and (max-width: 749px) {
    min-height: calc(100vh - 430px);
  }
  .demo-header {
    font-size: 36px;
    font-weight: bold;
    padding-top: 60px;
    @media screen and (max-width: 1000px) {
      width: 100%;
      padding-left: 40px;
      box-sizing: border-box;
      padding-top: 100px;
    }
    @media screen and (max-width: 749px) {
      width: 100%;
      padding-left: 20px;
      padding-top: 30px;
      font-size: 32px;
    }
  }
  .demo-tip {
    margin-top: 40px;
    @media screen and (max-width: 1000px) {
      width: 100%;
      padding-left: 40px;
      padding-right: 40px;
      box-sizing: border-box;
    }
    @media screen and (max-width: 749px) {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .demo-post {
    width: 940px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 40px;
    @media screen and (max-width: 1000px) {
      width: calc(100% - 80px);
      box-sizing: border-box;
    }
    @media screen and (max-width: 749px) {
      width: calc(100% - 40px);
    }
    .demo-item {
      width: 450px;
      height: 40px;
      border-radius: 4px;
      border: 0.5px solid #bbb;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      @media screen and (max-width: 1000px) {
        width: 100%;
        height: 44px;
        margin-bottom: 15px;
      }
      .item-left {
        display: flex;
        align-items: center;
        width: 150px;
        .dot {
          font-size: 14px;
          color: #f33b3b;
          margin-left: 15px;
        }
        .label-text {
          font-size: 14px;
          margin-left: 4px;
        }
      }
      .right-input {
        width: calc(100% - 150px);
        padding-right: 15px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        outline: none;
        border: none;
        background: #fcfcfc;
        @media screen and (max-width: 749px) {
          width: 100%;
        }
      }
    }
    .textarea-bottom {
      width: 100%;
      height: 172px;
      border: 0.5px solid #bbb;
      border-radius: 4px;
      position: relative;
      .top {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .dot {
          font-size: 14px;
          color: #f33b3b;
          margin-left: 15px;
        }
        .label-text {
          font-size: 14px;
          margin-left: 4px;
        }
      }
      .bottom-textarea {
        margin-top: 20px;
        padding: 0 25px;
        width: 100%;
        height: 115px;
        box-sizing: border-box;
        border: none;
        outline: none;
        resize: none;
        font-family: Source Han Sans CN;
        background: #fcfcfc;
      }
      .text-num {
        position: absolute;
        bottom: 23px;
        right: 20px;
        font-size: 14px;
        color: #bbb;
      }
    }
  }
  .post-btn {
    width: 300px;
    height: 40px;
    background: rgba(38, 143, 248, 1);
    border-radius: 30px;
    color: #fff;
    margin-top: 60px;
    margin-bottom: 20px;
    user-select: none;
    @media screen and (max-width: 749px) {
      width: calc(100% - 40px);
      height: 44px;
      margin-top: 110px;
    }
    &:hover {
      cursor: pointer;
      background: #3c9af9;
    }
  }
}
</style>