<template>
  <div class="menu-downlist" :class="[value ? 'menu-downlist-show' : '']">
    <div :class="[nowCheckedValue === item.label ? 'menu-item-a' : '']" v-for="(item,index) in listArr" :key="item.value" class="menu-item">
      <div @click="listChange(item.label)" :style="{border:index === (listArr.length - 1) ? '0' : ''}" class="item-inner">{{item.text}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    value:{
      type:Boolean,
      default:false
    },
    nowCheckedValue:{
      type:String,
      default:'home'
    }

  },
  data () {
    return {
      listArr:[
        {text:'首页',value:1,label:'home'},
        {text:'下载',value:2,label:'download'},
        {text:'定价',value:3,label:'price'},
        {text:'商务合作',value:4,label:'businessCooperation'},
        {text:'集团版',value:5,label:'business'}
      ]
    };
  },
  methods:{
    listChange(label){
      this.$emit('input',false);
      if(label === 'businessCooperation' || label === 'business'){
        this.$router.push('/' + label)
      }else{
        this.$emit('toPagePlace',label);
      }
    }
  }
};
</script>

<style scoped lang='scss'>
.menu-downlist{
  position: absolute;
  top: 70px;
  left: 0;
  width: 100vw;
  border-top: 0.5px solid #ccc;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(47,124,191,0.16);
  overflow: hidden;
  transform-origin: 0 0;
  transform: scaleY(0);
  transition: all 0.2s ease-in-out;
  display: none;
  @media screen and (max-width: 749px) {
    display: block;
  }
  
  .menu-item{
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    &:hover{
      cursor: pointer;
      background: #eee;
    }
    .item-inner{
      width: 100%;
      height: 54px;
      display: flex;
      align-items: center;
      font-size: 15px;
      border-bottom: 0.5px solid #eee;
    }
  }
  .menu-item-a{
    color:#268FF8;
    font-weight: 500;
  }

}
.menu-downlist-show{
  transform: scaleY(1);
}
</style>