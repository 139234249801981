<template>
  <div class="business-cooperation">
    <NavigatorHeader/>
    <appointmentDemo/>
    <CommonFooter/>
  </div>
</template>

<script>
import appointmentDemo from './appointmentDemo.vue';
import NavigatorHeader from '@/components/NavigatorHeader';
import CommonFooter from '@/components/CommonFooter';
export default {
  components: {
    NavigatorHeader,
    CommonFooter,
    appointmentDemo
  },
  data () {
    return {
      
    };
  },
};
</script>

<style scoped lang='scss'>
  .business-cooperation{
    padding-top: 70px;

  }
</style>